import axios from 'axios';
import { isNull } from 'lodash';
import { HOST_API, INACTIVITY_TIMEOUT_VALUE, PUBLIC_USER_JWT_TOKEN } from '../config';

const accessToken = window.localStorage.getItem('@reufy-accessToken');
const INACTIVITY_TIMEOUT = parseInt(INACTIVITY_TIMEOUT_VALUE, 10);

const handleLogout = () => {
  window.localStorage.removeItem('@reufy-accessToken');
  window.localStorage.removeItem('@reufyUser');
  window.location.href = '/login';
};

let inactivityTimer;

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);

  inactivityTimer = setTimeout(() => {
    handleLogout();
  }, INACTIVITY_TIMEOUT);
};

const setupInactivityListeners = () => {
  ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'].forEach(event => {
    window.addEventListener(event, resetInactivityTimer);
  });
};

setupInactivityListeners();
resetInactivityTimer();

const headers = () => {
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
};

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: headers(),
});

const shouldIncludeToken = (url) => {
  const protectedRoutesPattern = /^\/public\/professional\/[a-zA-Z0-9-_]+/;
  return protectedRoutesPattern.test(url);
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = window.localStorage.getItem('@reufy-accessToken');

    if (shouldIncludeToken(config.url) && isNull(accessToken)) {
      const userPublicJWTToken = PUBLIC_USER_JWT_TOKEN || '';
      window.localStorage.setItem('@reufy-accessToken', userPublicJWTToken);
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    resetInactivityTimer();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    if (status === 401 && error.config.url !== '/auth/login') {
      handleLogout();

      return Promise.reject(error);
    }

    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);


export default axiosInstance;

