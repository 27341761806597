import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { permissionEnum } from './PermissionEnum';

const PermissionGuard = ({ requiredPermission, children }) => {
  const userPermissions = getUserPermissions();
  if (
    userPermissions.includes(requiredPermission) ||
    userPermissions.includes(permissionEnum.Admin)
  ) {
    return <>{children}</>;
  }
  return <Navigate to="/admin/dashboard" />;
};

export const getUserPermissions = () => {
  try {
    const token = localStorage.getItem('@reufy-accessToken');
    if (!token) return [];
    const decodedToken = jwtDecode(token);
    return decodedToken.role || [];
  } catch (error) {
    console.error('Token inválido:', error);
    return [];
  }
};
export default PermissionGuard;
