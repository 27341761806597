import { Navigate, useRoutes } from 'react-router-dom';
import { Loadable } from '@components/common/loader';
import { lazy } from 'react';
import PermissionGuard from 'src/guards/PermissionGuard';
import { permissionEnum } from 'src/guards/PermissionEnum';
import { MainLayout, LogedLayout } from '../layouts';
import AuthGuard from '../guards/AuthGuard';

const Perfil = Loadable(lazy(() => import('../pages/profile/Perfil')));
const SchedulePage = Loadable(
  lazy(() => import('../pages/schedule/SchedulePage'))
);
const VerifiedPage = Loadable(
  lazy(() => import('../pages/verified/VerifiedPage'))
);
const Paciente = Loadable(lazy(() => import('../pages/profile/Paciente')));
const Consulta = Loadable(lazy(() => import('../pages/profile/Consulta')));
const Sucesso = Loadable(lazy(() => import('../pages/profile/Sucesso')));
const Pacientes = Loadable(lazy(() => import('../pages/pacientes/Pacientes')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const Onboarding = Loadable(lazy(() => import('../pages/onboarding')));
const PacienteInfo = Loadable(
  lazy(() => import('../pages/pacientes/PacienteInfo'))
);
const PacienteStatus = Loadable(
  lazy(() => import('../pages/pacientes/PacienteStatus'))
);
const SendImagesCell = Loadable(
  lazy(() => import('../pages/pacientes/components/SendImagesCell'))
);
const ClinicSettingsPage = Loadable(
  lazy(() => import('../pages/clinic_settings/ClinicSettingsPage'))
);
const ProfessionalSettingsPage = Loadable(
  lazy(() => import('../pages/professional_settings/ProfessionalSettingsPage'))
);
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const LoginPage = Loadable(lazy(() => import('../pages/login/LoginPage')));
const RecoverPassword = Loadable(
  lazy(() => import('../pages/recover_password/RecoverPassword'))
);
const NewPassword = Loadable(
  lazy(() => import('../pages/recover_password/NewPassword'))
);
const InvitationPage = Loadable(
  lazy(() => import('../pages/invitation/InvitationPage'))
);
const RegisterPage = Loadable(
  lazy(() => import('../pages/register/RegisterPage'))
);
const ClinicProfilePage = Loadable(
  lazy(() => import('../pages/clinic_profile/ClinicProfilePage'))
);
const Finance = Loadable(lazy(() => import('../pages/finance/Finance')));
const Tiss = Loadable(lazy(() => import('../pages/tiss/Tiss')));
const ContractsPage = Loadable(
  lazy(() => import('../pages/contracts/ContractsPage'))
);
const WorkloadTabsPage = Loadable(
  lazy(() => import('../pages/workload/WorkloadTabsPage'))
);
const RoomTabsPage = Loadable(
  lazy(() => import('../pages/resources/ResourceTabsPage'))
);
const ReportTabs = Loadable(lazy(() => import('../pages/reports/ReportTabs')));

export default function Router() {
  const publicRoutes = [
    {
      path: '/',
      element: <Navigate to="/admin/dashboard" replace />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/recover-password',
      element: <RecoverPassword />,
    },
    {
      path: '/recover-password/new-password',
      element: <NewPassword />,
    },
    {
      path: '/verification',
      element: <VerifiedPage />,
    },
    {
      path: '/invitation/:invite',
      element: <InvitationPage />,
    },
    {
      path: '/cadastro',
      element: <RegisterPage />,
    },
    {
      path: '/publico',
      element: <MainLayout />,
      children: [
        {
          element: (
            <Navigate to="/publico/profissional/:professionalId/perfil" />
          ),
          index: true,
        },
        {
          path: '/publico/profissional/:professionalId/perfil',
          element: <Perfil />,
        },
        {
          path: '/publico/profissional/:professionalId/paciente',
          element: <Paciente />,
        },
        {
          path: '/publico/profissional/:professionalId/consulta',
          element: <Consulta />,
        },
        {
          path: '/publico/profissional/:professionalId/sucesso',
          element: <Sucesso />,
        },
      ],
    },
    {
      path: '/:friendlyUrl',
      element: <ClinicProfilePage />,
    },
    {
      path: '/envio-imagens/:attendanceId/:accessToken',
      element: <SendImagesCell />,
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ];

  const privateRoutes = [
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <LogedLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/admin/dashboard',
          children: [
            {
              path: '/admin/dashboard',
              element: <Dashboard />,
            },
          ],
        },
        {
          path: '/admin/onboarding',
          children: [
            {
              path: '/admin/onboarding',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Onboarding}>
                  <Onboarding />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/painel',
          children: [
            {
              path: '/admin/painel',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Scheduling}>
                  <SchedulePage />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/pacientes',
          children: [
            {
              path: '/admin/pacientes/status/:id',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Patients}>
                  <PacienteStatus />
                </PermissionGuard>
              ),
            },
            {
              path: '/admin/pacientes',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Patients}>
                  <Pacientes />
                </PermissionGuard>
              ),
            },
            {
              path: '/admin/pacientes/:id',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Patients}>
                  <PacienteInfo />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/agenda',
          children: [
            {
              path: '/admin/agenda',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Scheduling}>
                  <SchedulePage />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/clinica',
          children: [
            {
              path: '/admin/clinica',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.ClinicSettings}>
                  <ClinicSettingsPage />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/profissional',
          children: [
            {
              path: '/admin/profissional',
              element: <ProfessionalSettingsPage />,
            },
          ],
        },
        {
          path: '/admin/financas',
          children: [
            {
              path: '/admin/financas',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Finance}>
                  <Finance />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/contratos',
          children: [
            {
              path: '/admin/contratos',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Contracts}>
                  <ContractsPage />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/carga-horaria',
          children: [
            {
              path: '/admin/carga-horaria',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Workload}>
                  <WorkloadTabsPage />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/salas',
          children: [
            {
              path: '/admin/salas',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Salas}>
                  <RoomTabsPage />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/relatorios',
          children: [
            {
              path: '/admin/relatorios',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Reports}>
                  <ReportTabs />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: '/admin/tiss',
          children: [
            {
              path: '/admin/tiss',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Tiss}>
                  <Tiss />
                </PermissionGuard>
              ),
            },
            {
              path: '/admin/tiss/:scheduleDetailsId',
              element: (
                <PermissionGuard requiredPermission={permissionEnum.Tiss}>
                  <Tiss />
                </PermissionGuard>
              ),
            },
          ],
        },
      ],
    },
  ];

  return useRoutes([...privateRoutes, ...publicRoutes]);
}
