export enum permissionEnum {
  ProfessionalHealth = 'professionalHealth',
  Admin = 'administrator',
  Secretary = 'secretary',
  Onboarding = 'onboarding',
  Scheduling = 'scheduling',
  Patients = 'patients',
  ClinicSettings = 'clinicSettings',
  ProfessionalSettings = 'professionalSettings',
  Finance = 'finance',
  Contracts = 'contracts',
  Workload = 'workload',
  Salas = 'salas',
  Reports = 'reports',
  Tiss = 'tiss',
}
