import 'moment/locale/pt-br';

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 64,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};
export const HOST_API =
  process.env.REACT_APP_API_URL || 'https://api.homolog.reufy.com.br';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://homolog.reufy.com.br';

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const REUFYPAY_URL_REGISTER = process.env.REACT_APP_REUFYPAY_URL_REGISTER;
export const REUFYPAY_URL_AUTH = process.env.REACT_APP_REUFYPAY_URL_AUTH;
export const REUFYPAY_URL_CLIENT_ID = process.env.REACT_APP_REUFYPAY_CLIENT_ID;
export const REUFYPAY_URL_REDIRECT_URI = process.env.REACT_APP_REUFYPAY_REDIRECT_URI;

export const PUBLIC_USER_JWT_TOKEN = process.env.REACT_APP_PUBLIC_USER_JWT_TOKEN;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const INACTIVITY_TIMEOUT_VALUE = process.env.REACT_APP_INACTIVITY_TIMEOUT_VALUE || 360000;
