import { permissionEnum } from 'src/guards/PermissionEnum';
import { getUserPermissions } from 'src/guards/PermissionGuard';

const useIsProfessional = () => {
  const permissions = new Set(getUserPermissions());
  return (
    permissions.has(permissionEnum.ProfessionalHealth) &&
    !permissions.has(permissionEnum.Admin) &&
    !permissions.has(permissionEnum.Secretary)
  );
};

export default useIsProfessional;