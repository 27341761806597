import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../utils/axios';

const initialState = {
  selectedGroup: null,
  roleGroups: [],
  isLoading: false,
  error: null,
  charges: [],
};

export const fetchRoleGroups = createAsyncThunk(
  'permission-group/fetchRoleGroups',
  async clinicId => {
    const response = await axios.get(`/roleGroups?clinicId=${clinicId}`);
    return response.data;
  }
);

export const createPermissionGroup = createAsyncThunk(
  'permission-group/createPermissionGroup',
  async group => {
    const response = await axios.post(`/roleGroups`, group);
    return response.data;
  }
);

export const updateRoleGroup = createAsyncThunk(
  'permission-group/createPermissionGroup',
  async group => {
    const response = await axios.put(`/roleGroups/${group._id}`, group);
    return response.data;
  }
);

export const deletePermissionGroup = createAsyncThunk(
  'permission-group/deletePermissionGroup',
  async groupId => {
    const response = await axios.delete(`/permission-group/${groupId}`);
    return response.data;
  }
);

export const permissionGroupSlice = createSlice({
  name: 'permissionGroup',
  initialState,
  reducers: {
    selectGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRoleGroups.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createPermissionGroup.pending, state => {
        state.isLoading = true;
      })
      .addCase(deletePermissionGroup.pending, state => {
        state.isLoading = true;
      })
      .addCase(createPermissionGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchRoleGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.roleGroups = action.payload;
      })
      .addCase(deletePermissionGroup.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createPermissionGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchRoleGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deletePermissionGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectGroup } = permissionGroupSlice.actions;
