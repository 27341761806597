// components
import Iconify from '../Iconify';
import SvgIconStyle from '../SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = name => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  hospital: getIcon('ic_hospital'),
  money: getIcon('ic_money'),
  users: getIcon('ic_users'),
  computer: getIcon('ic_computer'),
  graph: getIcon('ic_graph'),
  dataMigration: getIcon('ic_migrate'),
  notebook: getIcon('ic_notebook'),
  medic: getIcon('ic_medic'),
  hearts: getIcon('ic_hearts'),
  computerUser: getIcon('ic_computerUser'),
  financeGraph: getIcon('ic_financeGraph'),
  clock2: getIcon('ic_clock-2'),
  category: getIcon('ic_category'),
  transactions: getIcon('ic_transactions'),
  flux: getIcon('ic_flux'),
  pastes: getIcon('ic_pastes'),
  armchair: getIcon('ic_armchair'),
  door: getIcon('ic_door'),
  calendarEvent: getIcon('ic_calendarEvent'),
  calendarStats: getIcon('ic_calendarStats'),
  list: getIcon('ic_list'),
};

export const financialNavConfig = [
  {
    subheader: 'Financeiro',
    items: [
      {
        title: 'Resumo Financeiro',
        id: 0,
        icon: ICONS.financeGraph,
      },
      {
        title: 'Entradas/Saídas',
        id: 1,
        icon: ICONS.transactions,
      },
      {
        title: 'Fluxo de Caixa',
        id: 2,
        icon: ICONS.flux,
      },
    ],
  },
];
export const contractsNavConfig = [
  {
    subheader: 'Contratos',
    items: [
      {
        title: 'Fornecedores',
        id: 0,
        icon: ICONS.list,
      },
    ],
  },
];

export const workloadNavConfig = [
  {
    subheader: 'Gestão de carga horária',
    items: [
      {
        title: 'Carga Horária',
        id: 0,
        icon: ICONS.clock2,
      },
      {
        title: 'Categorias',
        id: 1,
        icon: ICONS.category,
      },
    ],
  },
];

export const roomsConfig = [
  {
    subheader: 'Gestão de espaço',
    items: [
      {
        title: 'Reservar Espaço',
        id: 0,
        icon: ICONS.calendarStats,
      },
      {
        title: 'Cadastrar Espaço',
        id: 1,
        icon: ICONS.door,
      },
      {
        title: 'Meus Espaços',
        id: 2,
        icon: ICONS.armchair,
      },
      {
        title: 'Minhas Reservas',
        id: 3,
        icon: ICONS.calendarEvent,
      },
      {
        title: 'Categorias',
        id: 4,
        icon: ICONS.category,
      },
    ],
  },
];

export const reportsConfig = [
  {
    subheader: 'Relatórios',
    items: [
      {
        title: 'Fluxo de Caixa',
        id: 0,
        icon: <Iconify icon="tabler:report-money" />,
      },
      {
        title: 'Financeiro',
        id: 1,
        icon: <Iconify icon="map:finance" />,
      },
      {
        title: 'Pagamentos de pacientes',
        id: 2,
        icon: <Iconify icon="fluent:patient-20-filled" />,
      },
      {
        title: 'Acompanhamento de prontuários',
        id: 3,
        icon: <Iconify icon="bi:file-medical" />,
      },
      {
        title: 'Dados Sócio Demográficos',
        id: 4,
        icon: <Iconify icon="material-symbols:demography" />,
      },
      {
        title: 'Atendimentos',
        id: 5,
        icon: (
          <Iconify icon="material-symbols-light:medical-services-outline" />
        ),
      },
    ],
  },
];

export const profissionalNavConfig = [
  {
    subheader: 'Configurações do profissional',
    items: [
      {
        title: 'Cadastro',
        id: 0,
        icon: ICONS.medic,
      },
      {
        title: 'Procedimentos',
        id: 1,
        icon: ICONS.hearts,
      },
      {
        title: 'Perfil Online',
        id: 2,
        icon: ICONS.computerUser,
      },
    ],
  },
];

export const clinicNavConfig = [
  {
    subheader: 'Configurações da clínica',
    items: [
      {
        title: 'Cadastro',
        id: 0,
        icon: ICONS.hospital,
      },
      {
        title: 'Profissionais',
        id: 1,
        icon: ICONS.users,
      },
      {
        title: 'Convênios',
        id: 2,
        icon: ICONS.notebook,
      },
      {
        title: 'Grupos de Usuários',
        id: 7,
        icon: <Iconify icon="hugeicons:locked" />,
      },
      {
        title: 'Financeiro',
        id: 3,
        icon: ICONS.graph,
        children: [
          {
            id: 100,
            title: 'Contas',
          },
          {
            id: 101,
            title: 'Centros de Custo',
          },
          {
            id: 102,
            title: 'Categorias Financeiras',
          },
          // {/* TODO comentado conforme task 1301 */},
          // {
          //   id: 103,
          //   title: 'Regras de Repasse',
          // },
        ],
      },
      {
        title: 'Migrar dados',
        id: 5,
        icon: ICONS.dataMigration,
        children: [
          {
            id: 106,
            title: 'Dados de Migração',
          },
          {
            id: 107,
            title: 'Status da Migração de Dados',
          },
        ],
      },
      {
        title: 'Agendamento Online',
        id: 4,
        icon: ICONS.computer,
        children: [
          {
            id: 104,
            title: 'Página Web',
          },
          {
            id: 105,
            title: 'Formas de pagamento',
          },
        ],
      },
      {
        title: 'Integração ReufyPay',
        id: 6,
        icon: ICONS.money,
      },
    ],
  },
];

export const tissNavConfig = [
  {
    subheader: 'TISS',
    items: [
      {
        title: 'Guias de Consulta',
        id: 0,
        subId: [100],
        icon: ICONS.notebook,
      },
      {
        title: 'Lotes',
        id: 1,
        subId: [101],
        icon: ICONS.pastes,
      },
    ],
  },
];

export const pacientsNavConfig = [
  {
    items: [
      {
        title: 'Anamnese',
        id: 0,
      },
      {
        title: 'Exames',
        id: 1,
      },
      {
        title: 'CID',
        id: 2,
      },
      {
        title: 'Imagens e Anexos',
        id: 3,
      },
      {
        title: 'Histórico do Paciente',
        id: 4,
      },
    ],
  },
];

export const pacientsNavConfigNotService = [
  {
    items: [
      {
        title: 'Histórico do Paciente',
        id: 4,
      },
    ],
  },
];
