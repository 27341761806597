import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import { permissionGroupSlice } from 'src/pages/clinic_settings/components/PermissionGroups/slices/PermissionGroupSlice';
import calendarReducer from './slices/schedule/schedule';
import managementReducer from './slices/management/management';
import navBarReducer from './slices/navBar';
import userReducer from './slices/user';
import loginSlice from '../pages/login/slices/loginSlice';
import dataMigrationSlice from '../pages/clinic_settings/slices/dataMigrationSlice';
import clinicSlice from '../pages/clinic_settings/slices/clinicSlice';
import registerSlice from '../pages/register/slices/registerSlice';
import RegisterProfessionalSlice from '../pages/professional_settings/slices/registerProfessionalSlice';
import PacientSlice from '../pages/pacientes/slices/pacientSlice';
import TissSlice from '../pages/tiss/slices/tissSlice';
import clinicProfileSlice from '../pages/clinic_profile/slices/clinicProfileSlice';
import professionalProfileSlice from '../pages/profile/slices/professionalProfileSlice';
import FinanceSlice from '../pages/finance/slices/financeSlice';
import { contractsSlice } from '../pages/contracts/slices/contractsSlice';
import workloadReducer from '../pages/workload/slices/workloadSlice';
import workloadCategoryReducer from '../pages/workload/workload-category/slices/workloadCategorySlice';
import resourceCategoryReducer from '../pages/resources/resource-categories/slices/resourceCategorySlice';
import scheduledResourceReducer from '../pages/resources/resource-reserve-register/slices/resourceScheduling';
import resourceReducer from '../pages/resources/resource/slices/resourceSlice';
import reportReducer from '../pages/reports/slices/ReportsSlice';
import recoverPasswordSlice from '../pages/recover_password/slices/recoverPasswordSlice';
import { reufyPaySlice } from './slices/shared/reufyPaySlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  recoverPasswordSlice: recoverPasswordSlice.reducer,
  management: managementReducer,
  schedule: calendarReducer,
  navBar: navBarReducer,
  user: userReducer,
  clinic: clinicSlice.reducer,
  login: loginSlice.reducer,
  register: registerSlice.reducer,
  registerProfessional: RegisterProfessionalSlice.reducer,
  pacients: PacientSlice.reducer,
  tiss: TissSlice.reducer,
  clinicProfile: clinicProfileSlice.reducer,
  professionalProfile: professionalProfileSlice.reducer,
  finance: FinanceSlice.reducer,
  contracts: contractsSlice.reducer,
  workload: workloadReducer,
  workloadCategory: workloadCategoryReducer,
  resource: resourceReducer,
  resourceCategory: resourceCategoryReducer,
  scheduledResources: scheduledResourceReducer,
  reports: reportReducer,
  dataMigration: dataMigrationSlice.reducer,
  reufyPay: reufyPaySlice.reducer,
  permissionGroup: permissionGroupSlice.reducer,
});

export { rootPersistConfig, rootReducer };
