import { permissionEnum } from 'src/guards/PermissionEnum';
import { PageModel } from 'src/models/PageModel';

export const MenuPages: PageModel[] = [
  {
    label: 'Primeiros Passos',
    key: 'admin/onboarding',
    to: '/admin/onboarding',
    icon: 'mdi:steps',
    requiredPermission: permissionEnum.Onboarding,
  },
  {
    label: 'Indicadores',
    key: 'admin/dashboard',
    to: '/admin/dashboard',
  },
  {
    label: 'Agenda',
    key: 'admin/agenda',
    to: '/admin/agenda',
    requiredPermission: permissionEnum.Scheduling,
  },
  {
    label: 'Pacientes',
    key: 'admin/pacientes',
    to: '/admin/pacientes',
    requiredPermission: permissionEnum.Patients,
  },
  {
    label: 'TISS',
    key: 'admin/tiss',
    to: '/admin/tiss',
    icon: 'tabler:file-health',
    requiredPermission: permissionEnum.Tiss,
  },
  {
    label: 'Gestão',
    key: 'gestao',
    icon: 'tabler:report-money',
    children: [
      {
        label: 'Financeiro',
        key: 'admin/financas',
        to: '/admin/financas',
        icon: 'tabler:report-money',
        requiredPermission: permissionEnum.Finance,
      },
      {
        label: 'Contratos',
        key: 'admin/contratos',
        to: '/admin/contratos',
        icon: 'tabler:notes',
        requiredPermission: permissionEnum.Contracts,
      },
      {
        label: 'Carga Horária',
        key: 'admin/carga-horaria',
        to: '/admin/carga-horaria',
        icon: 'tabler:clock-2',
        requiredPermission: permissionEnum.Workload,
      },
      {
        label: 'Espaço',
        key: 'admin/salas',
        to: '/admin/salas',
        icon: 'material-symbols:schedule-outline',
        requiredPermission: permissionEnum.Salas,
      },
      {
        label: 'Relatórios',
        key: 'admin/relatorios',
        to: '/admin/relatorios',
        icon: 'mdi:report-finance',
        requiredPermission: permissionEnum.Reports,
      },
    ],
  },
];
